import React from "react";

export const ArrowIconLeft: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 41.98 41.98">
    <path
      d="M26.32,32,14.94,21,26.32,10"
      fill="none"
      stroke="#fff"
      stroke-linecap="round"
      stroke-width="1.42"
    ></path>
  </svg>
  );
};

export const ArrowIconRight: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 41.98 41.98">
    <path
      d="M15.65,32,27,21,15.65,10"
      fill="none"
      stroke="#fff"
      stroke-linecap="round"
      stroke-width="1.42"
    ></path>
  </svg>
  );
};