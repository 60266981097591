import {
  NavigateOptions,
  To,
  useNavigate,
} from "react-router-dom";

export const useScrollNavigate = () => {
  const _navigate = useNavigate();
  const scrollNavigate = (to: To, options?: NavigateOptions | undefined) => {
    _navigate(to, options);
    window.scrollTo(0, 0);
  };
  return scrollNavigate;
};
