export const terms = `
AVISO DE PRIVACIDAD 
 
En consonancia a las normativas vigentes en materia de datos personales, queremos
hacerle conocer a los invitados e inscritos al evento las medidas a adoptar para
garantizar la privacidad de la información que nos suministren (en adelante la
"Información"), las cuales detallamos a continuación: 
 
1. Proteger la información de acuerdo con estándares de seguridad y confidencialidad. 
2. Limitar la obtención y el uso de la información al mínimo necesario para administrar y
brindarles un servicio adecuado a nuestros clientes, e invitados, lo que incluye
asesorarlos sobre nuestros productos, servicios y demás oportunidades. 
3. Permitir el acceso a la información únicamente a personas autorizadas por nuestra
empresa (sean dependientes o no), las que estarán debidamente capacitadas en el
manejo apropiado de la misma y serán pasibles de sanciones en caso de incumplimiento. 
4. No revelar la información (cuando legalmente se requiera consentimiento previo) a
terceras personas, a menos que hayamos informado previamente al cliente y éste nos
haya autorizado o lo hagamos en virtud de una obligación legal. 
5. Exigir a aquellos que nos provean algún servicio que se adecuen a nuestros estándares
de seguridad y confidencialidad. 
6. Ofrecer a los clientes la posibilidad de ejercer los derechos de acceso, rectificación y
supresión de sus datos, cuando corresponda. 
 
Movistar Empresas – Academia de innovación 2023
`