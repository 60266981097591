import { useState } from "react";
import { useSpringCarousel } from "react-spring-carousel";
import styled from "styled-components";
import { getYoutubeThumbnail, useScrollNavigate } from "../../helpers";
import { WebinarCard } from "../WebinarCard";
//import _ from "lodash";

const CarouselWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CarouselBody = styled.div`
  height: max-content;
  width: 500px;
  max-width: 90vw;
  overflow: hidden;
  position: relative;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
`;

interface DotProps {
  active?: boolean;
}

const Dot = styled.div<DotProps>`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  ${({ active }) =>
    active
      ? `
    background-color: #1FBCEB;
  `
      : `
  border: 1px solid #707070;
  `}
`;

const CarouselMarkerWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

interface CarouselMarkerProps {
  currentItem: number;
  itemsQty: number;
}

const CarouselMarker: React.FC<CarouselMarkerProps> = ({
  currentItem,
  itemsQty,
}) => {
  return (
    <CarouselMarkerWrapper>
      {[...Array(itemsQty)].map((el, index) => (
        <Dot active={currentItem === index} key={index} />
      ))}
    </CarouselMarkerWrapper>
  );
};

interface IProps {
  items: RecordedCourse[];
}

const WebinarsCarouselCard = styled(WebinarCard)`
  transform: scale(0.9);
  @media (min-width: 500px){
    transform: unset;
    margin: auto;
    margin-bottom: 30px;
  }
  
`

export const WebinarsCarousel: React.FC<IProps> = ({ items }) => {
  const navigate = useScrollNavigate();
  const { carouselFragment, getCurrentActiveItem, useListenToCustomEvent } =
    useSpringCarousel({
      items: items.map(
        ({ category, title, description, youtubeUrl, id }, index) => ({
          id: "id-" + index,
          renderItem: (
            <WebinarsCarouselCard
              category={category}
              title={title}
              description={description}
              onClickTitle={() => navigate(`/biblioteca/${id}`)}
              //style={{ transform: "scale(0.9)", marginLeft: "20px" }}
              key={index}
              thumbnail={
                youtubeUrl ? getYoutubeThumbnail(youtubeUrl) : undefined
              }
            />
          ),
        })
      ),

    });

  const { index } = getCurrentActiveItem();

  const [currentItem, setCurrentItem] = useState(index);

  const refreshMarker = () => {
    setCurrentItem(getCurrentActiveItem().index);
  };

  useListenToCustomEvent((event) => {
    if (event.eventName === "onSlideChange") {
      refreshMarker();
    }
  });

  return (
    <CarouselWrapper>
      <CarouselBody>{carouselFragment}</CarouselBody>
      <CarouselMarker itemsQty={items.length} currentItem={currentItem} />
    </CarouselWrapper>
  );
};
