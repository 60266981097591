import styled from "styled-components";
import { RoundedButton } from "../RoundedButton";
import { Subtitle, Title, Text } from "../Typhographies";
import downChevron from "../../assets/images/down-chevron.png";
import profileSrc from "../../assets/images/profile.svg";
import linkedinLogoSrc from "../../assets/images/linkedin-logo.svg";
import { useState } from "react";
import { useScrollNavigate } from "../../helpers";
import moment from "moment";
import "moment/locale/es";

moment.locale("es");

const CourseDataTitle = styled(Title)`
  line-height: 1.5rem;
  margin-bottom: 50px;
`;

const CourseDataSubject = styled.div`
  margin-bottom: 20px;
`;

const CourseDataRoundedButton = styled(RoundedButton)`
  margin-top: 40px;
`;

interface CourseDataModulesWrapperProps {
  dark?: boolean;
  extended?: boolean;
}

const CourseDataModulesWrapper = styled.div<CourseDataModulesWrapperProps>`
  padding: 10px;
  padding-left: 20px;
  padding-right: 60px;
  padding-bottom: 20px;
  background-color: ${({ dark }) => (dark ? "#f2f2f2" : "white")};
  ${({ extended }) =>
    extended
      ? `
      width: 100%; 
    padding-right: 20px;
    margin-top: 50px;
    `
      : "width:max-content;"}
  @media (max-width: 600px) {
    width: 100%;
  }
`;

const CourseDataModulesListPoint = styled.div`
  width: 8px;
  height: 8px;
  min-width: 8px;
  min-height: 8px;
  margin-right: 15px;
  border-radius: 50%;
  background-color: purple;
`;

const CourseDataModulesListItem = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

export const SubjectsList: React.FC<{ subjects: string[] }> = ({
  subjects,
}) => {
  return (
    <>
      {subjects.map((subject, index) => (
        <CourseDataModulesListItem key={index}>
          <CourseDataModulesListPoint />
          <Text>{subject}</Text>
        </CourseDataModulesListItem>
      ))}
    </>
  );
};

const CourseDatacourseModulesListItem = styled(CourseDataModulesListItem)`
  border-bottom: 1px solid #c9c9c9;
  padding-bottom: 10px;
  padding-right: 5px;
  &:hover {
    cursor: pointer;
  }
`;

interface CourseDatacourseModulesListItemChevronProps {
  selected?: boolean;
}

const CourseDatacourseModulesListItemChevronImg = styled.img<CourseDatacourseModulesListItemChevronProps>`
  width: 0.9rem;
  height: 0.9rem;
  ${({ selected }) =>
    selected
      ? `
    transform: rotate(180deg);
  `
      : ""}
`;

interface TeacherprofileImageUrlWrapperProps {
  profileImageUrl?: string;
}

const TeacherprofileImageUrlWrapper = styled.div<TeacherprofileImageUrlWrapperProps>`
  min-width: 90px;
  min-height: 90px;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ profileImageUrl }) =>
    profileImageUrl
      ? `
  background: url(${profileImageUrl}) center center;
  background-size: cover;
  `
      : `
  background-color: #cbcbcb;
  `}
`;

interface TeacherprofileImageUrlProps {
  profileImageUrl?: string;
}

const TeacherprofileImageUrl: React.FC<TeacherprofileImageUrlProps> = ({
  profileImageUrl,
}) => {
  return (
    <TeacherprofileImageUrlWrapper profileImageUrl={profileImageUrl}>
      {!profileImageUrl && (
        <img
          alt="profile"
          style={{ height: "45px", width: "45px" }}
          src={profileSrc}
        />
      )}
    </TeacherprofileImageUrlWrapper>
  );
};

const SocialMediaButton = styled.img`
  width: 40px;
  height: 40px;

  &:hover {
    cursor: pointer;
  }
`;

interface TeacherProfileProps {
  teacher: Teacher;
}

const TeacherProfile: React.FC<TeacherProfileProps> = ({
  teacher: { name, title, linkedinUrl, profileImageUrl },
}) => {
  return (
    <div style={{ marginTop: "20px", display: "flex", gap: "10px" }}>
      <TeacherprofileImageUrl profileImageUrl={profileImageUrl} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          marginLeft: "10px",
        }}
      >
        <div>
          <Text
            style={{
              fontFamily: "telefonicaBold",
              fontSize: "1.3rem",
              lineHeight: "1.1rem",
            }}
          >
            {name}
          </Text>
          <Text style={{ fontFamily: "telefonicaBold" }}>{title}</Text>
        </div>

        {linkedinUrl && (
          <SocialMediaButton
            src={linkedinLogoSrc}
            onClick={() => window.open(linkedinUrl, "_blank")}
          />
        )}
      </div>
    </div>
  );
};

interface CourseDatacourseModulesListWrapperProps {
  selected?: boolean;
}

const CourseDatacourseModulesListItemDetailsWrapper = styled.div<CourseDatacourseModulesListWrapperProps>`
  border-bottom: 1px solid #c9c9c9;
  padding-bottom: 30px;
  max-height: 800px;
  ${({ selected }) =>
    selected ? "" : "max-height: 0px; padding: 0px; border: none;"}
  transition: max-height linear 0.5s;
  overflow: hidden;
`;

interface CourseDatacourseModulesListProps {
  modules: Module[];
}

const CourseDatacourseModulesList: React.FC<
  CourseDatacourseModulesListProps
> = ({ modules }) => {
  const [selectedIndex, setSelectedIndex] = useState<null | number>(null);
  return (
    <>
      {modules.map(({ name, moduleDate, description, teachers }, index) => {
        const selected = selectedIndex === index;
        return (
          <>
            <CourseDatacourseModulesListItem
              onClick={() => setSelectedIndex(selected ? null : index)}
              key={index + "a"}
            >
              <CourseDataModulesListPoint />
              <Text>
                <b>Clase {index + 1}: </b>
                ({moment(moduleDate, "YYYY-MM-DD").format("ddd DD/MM")}){" "}
                {name}
              </Text>
              <div style={{ flexGrow: 1 }} />
              <CourseDatacourseModulesListItemChevronImg
                selected={selected}
                src={downChevron}
              />
            </CourseDatacourseModulesListItem>
            <CourseDatacourseModulesListItemDetailsWrapper
              selected={selected}
              key={index + "b"}
            >
              <pre
                style={{
                  marginTop: "15px",
                  maxWidth: "850px",
                  wordWrap: "break-word",
                  whiteSpace: "pre-wrap",
                }}
              >
                {description}
              </pre>
              <div style={{ display: "flex", gap: "20px", flexWrap: "wrap" }}>
                {teachers.map((teacher, index) => (
                  <TeacherProfile teacher={teacher} key={index} />
                ))}
              </div>
            </CourseDatacourseModulesListItemDetailsWrapper>
          </>
        );
      })}
    </>
  );
};

interface IProps {
  course: Course;
  isDetails?: boolean;
  dark?: boolean;
}

export const CourseData: React.FC<IProps> = ({
  course: {
    title,
    description,
    objective,
    certified,
    courseModules,
    mode,
    schedule,
    id,
  },
  dark,
  isDetails,
}) => {
  const navigate = useScrollNavigate();
  return (
    <div>
      {!isDetails && <CourseDataTitle>{title}</CourseDataTitle>}
      {isDetails && description && (
        <>
          <CourseDataSubject>
            <Subtitle>Descripción</Subtitle>
            <Text>{description}</Text>
          </CourseDataSubject>
          <CourseDataSubject>
            <Subtitle>Horarios</Subtitle>
            <Text>{schedule?.map(schedule=><div>{schedule}</div>)}</Text>
          </CourseDataSubject>
        </>
      )}
      <CourseDataSubject>
        <Subtitle>Objetivo</Subtitle>
        <Text>{objective}</Text>
      </CourseDataSubject>
      {courseModules && (
        <CourseDataSubject>
          <Subtitle>Curso certificado</Subtitle>
          {courseModules.length > 1 && (
            <Text>
              Asistiendo a las {courseModules.length} clases del curso obtendrás un
              certificado online de tu conocimiento.
            </Text>
          )}
          {courseModules.length === 1 && (
            <Text>
              Asistiendo al curso obtendrás un certificado online de tu
              conocimiento.
            </Text>
          )}
        </CourseDataSubject>
      )}
      {mode === "online" && (
        <CourseDataSubject>
          <Subtitle>Modalidad</Subtitle>
          <Text>
            Las clases se desarrollarán en vivo, de forma online. Podrás
            interactuar con los speakers y preguntar sobre todas tus dudas.
          </Text>
        </CourseDataSubject>
      )}
      {isDetails && courseModules && courseModules.length && (
        <CourseDataModulesWrapper extended dark={!dark}>
          <Subtitle style={{ marginBottom: "15px" }}>Clases</Subtitle>
          <CourseDatacourseModulesList modules={courseModules} />
        </CourseDataModulesWrapper>
      )}
      {!isDetails && (
        <CourseDataRoundedButton
          filled
          onClick={() => navigate(`/cursos/${id}`)}
        >
          Ver más
        </CourseDataRoundedButton>
      )}
    </div>
  );
};
