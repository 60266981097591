import styled from "styled-components";
import requirements from "../../assets/images/requirements-1.svg";
import requirements2 from "../../assets/images/requirements-2.svg";
import requirements3 from "../../assets/images/requirements-3.svg";
import { RoundedButton } from "../../components";
import fixedBannerSrc from "../../assets/images/fixed-banner.webp";
import { CarouselHeader } from "../../components/CarouselHeader";
import { useScrollNavigate } from "../../helpers";
import { useGetBenefits } from "../../services/useGetBenefits";
import { useGetSection } from "../../services/useGetSection";
import { HomeHeader } from "../../components/HomeHeader";
import homeBanner from "../../assets/images/home-banner.jpg";
import homeBannerMobile from "../../assets/images/home-banner-mobile.jpg";
import { useGetCarousel } from "../../services/useGetCarousels";

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

interface InfoBlockWrapperProps {
  backgroundColor?: string;
  textColor?: string;
}

const HomePageRoundedButton = styled(RoundedButton)`
  margin-top: 60px;
`;

const InfoBlockWrapper = styled.div<InfoBlockWrapperProps>`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  padding-top: 60px;
  padding-bottom: 60px;
  width: 100%;
  ${({ backgroundColor }) =>
    backgroundColor ? `background-color:${backgroundColor};` : ""}

  ${({ textColor }) => (textColor ? `color:${textColor};` : "")}
`;

interface InfoBlockParagraphProps {
  fontSize?: string;
  bold?: boolean;
}

interface AlignText {
  align?: string;
}

const InfoBlockParagraph = styled.div<InfoBlockParagraphProps>`
  ${({ fontSize }) => `font-size:${fontSize ? fontSize : "1rem"};`}
  ${({ bold }) => (bold ? `font-family:'telefonicaBold';` : "")}
  max-width: 700px;
`;

const TextAlign = styled.div<AlignText>`
  ${({ align }) => `text-align:${align ? align : "center"};`}
  display: block;
  margin-bottom: 20px;
`;

const CardBlockParagraph = styled.div<InfoBlockParagraphProps>`
  ${({ fontSize }) => `font-size:${fontSize ? fontSize : "1rem"};`}
  ${({ bold }) => (bold ? `font-family:'telefonicaBold';` : "")}
  max-width: 700px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding-top: 10px;
  justify-content: center;
`;

const Card = styled.div`
background-color: #fff;
border: 1px solid #ccc;
border-radius: 8px;
padding: 10px;
margin-right: 10px;
width: 200px;
display: flex;
justify-content: center;
align-items: center;
`;

const InfoBlockTitle = styled.div`
  font-size: 1.3rem;
  font-weight: bold;
  font-family: "telefonicaBold";
  margin-bottom: 1rem;
`;

const DrawingsGrid = styled.div`
  display: grid;
  grid-template-columns: 4fr 4fr 4fr;
  grid-column-gap: 80px;
  margin-top: 50px;
  @media (max-width: 1050px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 80px;
  }
`;

const DrawingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const DrawingImg = styled.img`
  height: 250px;
  margin-bottom: 15px;
`;

const DrawingText = styled.div`
  font-size: 1rem;
  font-family: telefonicaBold;
`;

interface CountryType {
  code: string;
  country: string;
}

const countries: CountryType[] = [
  { code: "AR", country: "Argentina"},
  { code: "CL", country: "Chile"},
  { code: "CO", country: "Colombia"},
  { code: "PE", country: "Perú"},
  { code: "EC", country: "Ecuador"},
  { code: "MX", country: "México"},
  { code: "UY", country: "Uruguay"},
  { code: "VE", country: "Venezuela"},
]

export const HomePage: React.FC = () => {
  let navigate = useScrollNavigate();

  const { benefitData } = useGetBenefits();
  const { sectionData } = useGetSection();
  const { carouselData } = useGetCarousel("home");

  const singleItem = (
    <HomeHeader
      image={
        carouselData?.slides.length === 1
        ? {
          desktop: carouselData.slides[0]?.slideDesktopImageUrl || homeBanner,
          mobile: carouselData.slides[0]?.slideMobileImageUrl || homeBannerMobile
        }: null
      }
      title={carouselData?.slides[0]?.title}
    />
  );

  return (
    <>
      {carouselData && carouselData.slides.length > 1 ? (
        <CarouselHeader slides={carouselData.slides} />
      ) : (
        singleItem
      )}
      <PageWrapper>
        {sectionData ? (
          <InfoBlockWrapper>
            <InfoBlockTitle>{sectionData.title}</InfoBlockTitle>
            <InfoBlockParagraph>
              {sectionData.description}
            </InfoBlockParagraph>
          </InfoBlockWrapper>
        ): null}
        <InfoBlockWrapper backgroundColor="#0A2739" textColor="white">
          <InfoBlockTitle>Agenda tus próximas clases online</InfoBlockTitle>
          <InfoBlockParagraph>
            En nuestras clases online podrás certificar tus conocimientos y
            preguntar tus dudas en tiempo real a los expertos.
          </InfoBlockParagraph>
          <HomePageRoundedButton animated filled onClick={() => navigate("/cursos")}>
            Registrate
          </HomePageRoundedButton>
        </InfoBlockWrapper>
        {benefitData ? (
          <InfoBlockWrapper>
            <InfoBlockTitle>{benefitData.title}</InfoBlockTitle>
            <CardBlockParagraph>
              {benefitData.benefits.map((benefit, index)=> (
                <Card key={index}>{benefit}</Card>
              ))}
            </CardBlockParagraph>
          </InfoBlockWrapper>
        ): null}
        <InfoBlockWrapper
          id="librarySection"
          style={{
            backgroundImage: `url(${fixedBannerSrc})`,
            backgroundAttachment: "fixed",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
          textColor="white"
        >
          <InfoBlockParagraph fontSize="1.2rem" bold>
            Te invitamos a descubrir todos los cursos que tenemos para que
            desarrolles tus habilidades, las de tu equipo y te inspires en tu
            día a día.
          </InfoBlockParagraph>
          <HomePageRoundedButton animated filled onClick={() => navigate("/biblioteca")}>
            Biblioteca de cursos gratuitos
          </HomePageRoundedButton>
        </InfoBlockWrapper>
        <InfoBlockWrapper backgroundColor="#0A2739" textColor="white">
          <InfoBlockTitle>Sección cifras de impacto</InfoBlockTitle>
          <InfoBlockParagraph>
            <TextAlign align="left">En el 2022 hemos mejorado las capacidades de miles de empresas y emprendimientos en Hispanoamérica.</TextAlign>
            <TextAlign align="left">Países: 
              {countries.map(el => (
                <img style={{margin: '0 5px'}}
                key={el.country}
                loading="lazy"
                width="20"
                src={`https://flagcdn.com/w20/${el.code.toLowerCase()}.png`}
                srcSet={`https://flagcdn.com/w40/${el.code.toLowerCase()}.png 2x`}
                alt={el.country}
                title={el.country}
                />
              ))}
            </TextAlign>
            <TextAlign align="left">+6,000 pyme y emprendedores capacitados.</TextAlign>
            <TextAlign align="left">+2,000 visualizaciones de nuestra biblioteca de cursos</TextAlign>
            <TextAlign align="left">+13 capacitadores de distintas nacionalidades</TextAlign>
          </InfoBlockParagraph>
        </InfoBlockWrapper>
        <InfoBlockWrapper>
          <InfoBlockTitle>Requisitos</InfoBlockTitle>
          <DrawingsGrid>
            <DrawingWrapper>
              <DrawingImg src={requirements} />
              <DrawingText>Conexión a internet</DrawingText>
            </DrawingWrapper>
            <DrawingWrapper>
              <DrawingImg src={requirements2} />
              <DrawingText>Mente abierta y ganadora</DrawingText>
            </DrawingWrapper>
            <DrawingWrapper>
              <DrawingImg src={requirements3} />
              <DrawingText>Ganas de aprender e innovar</DrawingText>
            </DrawingWrapper>
          </DrawingsGrid>
        </InfoBlockWrapper>
      </PageWrapper>
    </>
  );
};
