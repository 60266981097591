import styled from "styled-components";
import triangledown from "../../assets/images/triangledown.png";
interface SelectProps {
  secondaryStyle?: boolean;
}

export const Select = styled.select<SelectProps>`
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid #707070;
  background-color: white;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1rem;

  @media (max-width: 600px) {
    min-width: 240px;
    font-size: 0.7rem;
    background-position-y: 6px;
  }
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  text-transform: uppercase;
  background-image: url(${triangledown});
  background-repeat: no-repeat;
  background-position-x: 96%;
  background-position-y: 10px;
  background-size: 18px;
  ${({ secondaryStyle }) =>
    secondaryStyle
      ? `
  border:none;
  border-bottom: 1px solid #707070;
  font-size: 15px;
  padding: 0px;
  padding-bottom: 0px;
  padding-top: 0px;
  background-color: initial !important;
  min-width: initial;
  text-align: left;
  height: 1.96rem;
  min-width: initial !important;
  `
      : ""}
`;

export const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SelectLabel = styled.label`
  font-family: telefonicaBold;
  font-size: 1rem;
  text-transform: uppercase;
  margin-bottom: 10px;
`;
