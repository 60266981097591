import axios from "axios";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import {
  CountrySelect,
  countries,
  companyCodeTypes,
  CourseData,
  CoursesHeader,
  Modal,
  PageContainerContent,
  PageContainerWrapper,
  RoundedButton,
  Select,
  Title,
  useModal,
} from "../../components";
import { env } from "../../config";
import { validateEmail } from "../../helpers";
import { useGetCourseDetails } from "../../services/useGetCourseDetails";
import { terms } from "./terms";

const SignUpFormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  padding: 25px;
  @media (max-width: 550px) {
    padding: 15px;
  }
  max-width: 100%;
`;

const SignUpFormWrapperBigScreen = styled.div`
  min-width: 440px;
  margin-top: -250px;

  top: 40px;
  background-color: #f2f2f2;
  height: max-content;
  @media (max-width: 1000px) {
    display: none;
  }
`;

const SignUpFormWrapperMobile = styled.div`
  @media (min-width: 1000px) {
    display: none;
  }
  display: flex;
  background-color: #f2f2f2;
  justify-content: center;
  max-width: 100%;
  @media (max-width: 550px) {
    padding: 10px;
  }
  width: 100%;
`;

const SignUpFormTitle = styled(Title)`
  text-align: center;
  font-size: 1.9rem;
  margin-bottom: 15px;
  line-height: 2.5rem;
`;

const SignUpFormLabel = styled.label<{ error?: boolean | any }>`
  font-family: telefonicaBold;
  text-transform: uppercase;
  font-size: 1.1rem;
  ${({ error }) => (error ? `color:#e24c4c !important;` : ``)}
  @media (max-width: 550px) {
    font-size: 1rem;
  }
`;

const Input = styled.input<{ error?: boolean | any }>`
  padding: "10px";
  background-image: none;
  outline: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border: none;

  border-bottom: 1px solid #707070;
  ${({ error }) =>
    error
      ? `
    border-bottom: 1px solid #e24c4c;
    color: #e24c4c;
  `
      : ``}
  font-size: 1rem;
  padding-bottom: 5px;
  padding-top: 5px;
  font-family: telefonicaWeb;
  margin-bottom: 8px;
`;

const SignUpButton = styled(RoundedButton)`
  @media (max-width: 550px) {
    min-width: initial;
  }
`;

const Checkbox = styled.input`
  width: 1.2rem;
  height: 1.2rem;
  border: 1px solid black;
  &:checked {
    border: none;
    background: url(checkboxSrc) no-repeat center center;
    background-size: contain;
  }
  border-radius: 3px;
  accent-color: #019af1;
`;

const TermsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
  margin-top: 20px;
  margin-bottom: 15px;
`;

const TermsButton = styled.button`
  appearance: none;
  border: none;
  background-color: none;
  text-transform: uppercase;
  background: none;
  font-size: 1rem;
  line-height: 1rem;
  font-family: telefonicaBold;
  text-align: left;
  & > b {
    color: #019af1;
    text-decoration: underline;
  }
  &:hover {
    appearance: none;
    border: none;
    background-color: none;
    background: none;
  }
  cursor: pointer;
`;


const SignUpForm: React.FC<{ courseId?: string }> = ({ courseId }) => {
  const { register, handleSubmit, formState, reset, control, watch } = useForm<any>({
    defaultValues: {
      termsAccepted: "true",
      offersAccepted: "true",  
    }
  });
  const selectedCountry = watch("countryObject"); 

  const onSubmit = handleSubmit(
    async ({ termsAccepted, ...data }) => {
      const notNullData = Object.fromEntries(
        Object.entries(data).filter(([key, value]) => Boolean(value))
      );
      if (termsAccepted) {
        if (data.countryObject != null) {
          notNullData.country = data.countryObject.label.toString();
          notNullData.phoneAreaCode =
            "+" + data.countryObject.phone.toString();
          delete notNullData.countryObject;
        }

        data.courseId = courseId;
        data.isClient = data.isClient === "true";

        toast.promise(
          axios
            .post(`${env.API_URL}/students/register`, {
              ...notNullData,
              //add plus sign if it doesn't exist

              courseId,
            })
            .then(() => {
              reset();
              //GTM push form submit event
              //@ts-ignore
              window.dataLayer.push({
                event: "submitCourseApplication",
              });
            }),
          {
            loading: "Enviando...",
            success: (
              <b>
                Te has registrado al curso!
                <br />
                En el transcurso del día te llegará un mail de confirmación
              </b>
            ),
            error: (err) => {
              return err.response.status === 409 ? (
                <b>Ya te has registrado en este curso.</b>
              ) : (
                <b>Ha ocurrido un error.</b>
              );
            },
          },
          {
            position: "bottom-center",
            duration: 5000,
          }
        );
      } else {
        toast.error(
          "Debes aceptar los Términos y Condiciones antes de inscribirte a un curso",
          {
            position: "bottom-center",
          }
        );
      }
    },
    () => {
      toast.error(
        "No se ha podido enviar la solicitud: Algunos campos son invalidos, corrigelos y vuelve a intentar",
        { position: "bottom-center" }
      );
    }
  );

  const { acceptButton, title, description, onClose, openModal, open } =
    useModal({});

  const onClickTerms = () =>
    openModal({
      title: "Terminos y condiciones",
      description: terms,
      acceptButtonTitle: "Leido",
      cb: () => {},
    });

  return (
    <>
      <SignUpFormWrapper onSubmit={onSubmit}>
        <SignUpFormTitle>Completa el formulario e inscríbete</SignUpFormTitle>
        <div
          style={{
            marginBottom: "12px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <SignUpFormLabel
            error={formState.errors["firstName"]}
            htmlFor="firstName"
          >
            Nombre
          </SignUpFormLabel>
          <Input
            {...register("firstName", { required: true, maxLength: 30 })}
            maxLength={30}
            error={formState.errors["firstName"]}
            id="firstName"
            placeholder="Nombre"
          />
        </div>
        <div
          style={{
            marginBottom: "12px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <SignUpFormLabel htmlFor="lastName">Apellido</SignUpFormLabel>
          <Input
            {...register("lastName", { maxLength: 30 })}
            id="lastName"
            placeholder="Apellido"
          />
        </div>
        <div
          style={{
            marginBottom: "12px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <SignUpFormLabel htmlFor="genderCode">Género</SignUpFormLabel>
          <Select
            {...register("gender")}
            style={{ width: "auto", outline: "none" }}
            id="gender"
            secondaryStyle
          >
            <option value="MASCULINO">Masculino</option>
            <option value="FEMENINO">Femenino</option>
            <option value="NOBINARIO">Prefiero no decir</option>
          </Select>
        </div>
        <div
          style={{
            display: "flex",
            marginBottom: "12px",
            marginTop: "12px",
            justifyContent: "space-between",
          }}
        >
          <SignUpFormLabel error={formState.errors["isClient"]} htmlFor="isClient">
            ¿Es cliente de movistar?
          </SignUpFormLabel>
          <div
            style={{
              display: "flex",
              gap: "27px",
              padding: "2px",
              marginRight: "25px",
            }}
          >
            <SignUpFormLabel htmlFor="isClient">SI</SignUpFormLabel>
            <input
              {...register("isClient", { required: true })}
              value="true"
              type="radio"
              id="clientAccepted"
              style={{ marginLeft: "-16px" }}
            />
            <SignUpFormLabel htmlFor="isClient">NO</SignUpFormLabel>
            <input
              {...register("isClient", { required: true })}
              type="radio"
              id="clientNoAccepted"
              value="false"
              defaultChecked={false}
              style={{ marginLeft: "-16px" }}
            />
          </div>
        </div>

        <div>
          <Controller
            control={control}
            name="countryObject"
            render={({ field }) => (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: "10px",
                    marginTop: "10px",
                  }}
                >
                  <SignUpFormLabel
                    htmlFor="country"
                  >
                    País
                  </SignUpFormLabel>
                  <CountrySelect
                    options={countries}
                    onChange={(event, value) => field.onChange(value)} // Actualiza el valor del campo "country" en el formulario
                    value={field.value && field.value.label}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: "15px",
                    marginTop: "18px",
                  }}
                >
                  <SignUpFormLabel htmlFor="phone">Teléfono</SignUpFormLabel>
                  <div style={{ display: "flex", gap: "10px" }}>
                    <Input
                      {...field}
                      style={{ width: "80px", textAlign: "center" }}
                      value={(field.value && field.value.phone) || ""}
                      maxLength={10}
                      placeholder="Cod. Área"
                      id="phoneAreaCode"
                      disabled
                    />
                    <Input
                      {...register("phone", {
                        maxLength: 30,
                        minLength: 6,
                        pattern: /^[0-9]*$/,
                      })}
                      style={{ flexGrow: 1 }}
                      maxLength={30}
                      id="phone"
                      placeholder="Télefono"
                      error={formState.errors["phone"]}
                    />
                  </div>
                </div>
              </>
            )}
          />
        </div>
        <div
          style={{
            marginBottom: "12px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <SignUpFormLabel error={formState.errors["email"]} htmlFor="email">
            Mail
          </SignUpFormLabel>
          <Input
            {...register("email", {
              required: true,
              validate: (val) => validateEmail(val),
            })}
            error={formState.errors["email"]}
            id="email"
            placeholder="Email"
          />
        </div>

        <SignUpFormLabel
          htmlFor="companyCode"
          style={{ marginTop: "10px", marginBottom: "10px" }}
        >
          Código de empresa
        </SignUpFormLabel>
        <div style={{ display: "flex", gap: "10px" }}>
          <Select
            {...register("companyCodeType")}
            style={{ width: "110px", outline: "none" }}
            id="companyCodeType"
            secondaryStyle
          >
            {(selectedCountry?.companyCodes||companyCodeTypes).map((type: string[]) => (
              <option value={type}>{type}</option>
            ))}
              <option value="Otro">Otro</option>
          </Select>

          <Input
            {...register("companyCode", { maxLength: 30 })}
            style={{ flexGrow: 1 }}
            maxLength={30}
            id="companyCode"
            error={formState.errors["companyCode"]}
          />
        </div>

        <TermsWrapper>
          <Checkbox {...register("termsAccepted")} type="checkbox" />
          <TermsButton type="button" onClick={onClickTerms}>
            Acepto los <b>términos y condiciones</b>
          </TermsButton>
        </TermsWrapper>

        {/* <TermsWrapper>
          <Checkbox
            {...register("offersAccepted")}
            type="checkbox"
            id="offersAccepted"
          />
        <TermsButton>QUIERO RECIBIR OFERTAS Y PROMOCIONES DE MOVISTAR EMPRESAS</TermsButton>
        </TermsWrapper> */}

        <SignUpButton type="submit" filled style={{ marginTop: "15px" }}>
          Quiero Inscribirme
        </SignUpButton>
      </SignUpFormWrapper>
      <Modal
        title={title}
        description={description}
        onClose={onClose}
        acceptButton={acceptButton}
        open={open}
      />
    </>
  );
};

const CourseSignUpPageContainer = styled(PageContainerContent)`
  @media (min-width: 1000px) {
    height: 1200px;
  }
  display: flex;
  gap: 40px;
`;

export const CourseSignUpPage: React.FC = () => {
  const { courseId } = useParams();
  const { course } = useGetCourseDetails(parseInt(courseId || "1"));
  return (
    <>
      {course && (
        <>
          <CoursesHeader shorterWidth title={course.title} certified />
          <PageContainerWrapper>
            <CourseSignUpPageContainer padding>
              <CourseData isDetails course={course} />
              <SignUpFormWrapperBigScreen>
                <SignUpForm courseId={courseId} />
              </SignUpFormWrapperBigScreen>
            </CourseSignUpPageContainer>
          </PageContainerWrapper>
          <SignUpFormWrapperMobile>
            <SignUpForm courseId={courseId} />
          </SignUpFormWrapperMobile>
        </>
      )}
    </>
  );
};
