import styled from "styled-components";
import movistarLogo from "../../assets/images/movistar-logo.svg";
import { useScrollNavigate } from "../../helpers";

const CompanyHeaderContainer = styled.div`
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0b2739;
  padding-top: 10px;
  padding-bottom: 10px;
  
`;

const CompanyLogo = styled.img`
  height: 70%;
  &:hover {
    cursor: pointer;
  }
`;

const HeaderBody = styled.div`
  width: 80%;
  height: 100%;
  display: flex;
  align-items: center;
  @media (max-width: 745px) {
    justify-content: center;
  }
`;

export const CompanyHeader: React.FC = () => {
  const navigate = useScrollNavigate();

  return (
    <CompanyHeaderContainer>
      <HeaderBody>
        <CompanyLogo
          src={movistarLogo}
          alt="logo"
          onClick={() => navigate("/")}
        />
      </HeaderBody>
    </CompanyHeaderContainer>
  );
};
