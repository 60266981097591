import { CarouselHeader, CourseDisplay } from "../../components";
import { useGetCourses } from "../../services/useGetCourses";
import homeBanner from "../../assets/images/home-banner.jpg";
import homeBannerMobile from "../../assets/images/home-banner-mobile.jpg";
import { HomeHeader } from "../../components/HomeHeader";
import { useGetCarousel } from "../../services/useGetCarousels";

export const CoursesPage: React.FC = () => {
  const { courses } = useGetCourses();
  const { carouselData } = useGetCarousel("cursos");

  const singleItem = (
    <HomeHeader
    image={
      carouselData?.slides.length === 1
      ? {
        desktop: carouselData.slides[0]?.slideDesktopImageUrl || homeBanner,
        mobile: carouselData.slides[0]?.slideMobileImageUrl || homeBannerMobile
      }: null
    }
    title={carouselData?.slides[0]?.title}
    />
  );
  return (
    <>
      {carouselData && carouselData.slides.length > 1 ? (
        <CarouselHeader slides={carouselData.slides} />
      ) : (
        singleItem
      )}
        {courses && courses.map((course, index) => (
          <CourseDisplay dark={index % 2 !== 0} course={course} key={index} />
        ))}
    </>
  );
};
