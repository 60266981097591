import { useState, useEffect } from "react";
import axios from "axios";
import { env } from "../../config";

export const useGetCourses = () => {
  const [courses, setCourses] = useState<Course[]>();
  const [coursesLoading, setCoursesLoading] = useState(true);
  useEffect(() => {
    (async () => {
      setCoursesLoading(true);
      const {
        data: {
          _embedded: { courses: _courses },
        },
      } = await axios.get<CoursesResponse>(
        `${env.API_URL}/courses?projection=coursesView`
      );
      setCourses(_courses);
      setCoursesLoading(false);
    })();
  }, []);
  return { courses, setCourses, coursesLoading };
};
