import styled from "styled-components";
import coursesBanner from "../../assets/images/courses-banner-filter.webp";
import { CertifiedCourseBadge } from "../Chip";
import { PageContainer } from "../PageContainer";

const CoursesHeaderWrapper = styled.div`
  width: 100%;
  height: 380px;
  background: url(${coursesBanner}) no-repeat left center;
  @media (max-width: 1000px) {
    background-position-x: 30%;
  }
  background-size: cover;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

interface CoursesHeaderTitleProps {
  shorterWidth?: boolean;
}

const CoursesHeaderTitle = styled.h1<CoursesHeaderTitleProps>`
  max-width: 90%;
  font-size: 2rem;
  line-height: 2.2rem;
  font-family: telefonicaBold;

  @media (min-width: 1000px) {
    max-width: 45%;
  }

  @media (min-width: 1200px) {
    font-size: 3rem;
    line-height: 3.4rem;
    max-width: 55%;
  }
`;


interface IProps {
  title: string;
  certified?: boolean;
  shorterWidth?: boolean;
}

export const CoursesHeader: React.FC<IProps> = ({ title, certified, shorterWidth }) => {
  return (
    <CoursesHeaderWrapper>
      <PageContainer containerPadding headerPadding>
        {certified && <CertifiedCourseBadge />}
        <CoursesHeaderTitle shorterWidth={shorterWidth}>
          {title}
        </CoursesHeaderTitle>
      </PageContainer>
    </CoursesHeaderWrapper>
  );
};