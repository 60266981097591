import styled from "styled-components";
import { PageContainer } from "../PageContainer";

interface HomeHeaderProps {
  title?: string;
  image: null | {
    desktop: string;
    mobile: string;
  };
}

interface ImagesHomeHeader {
  desktopImage: string,
  mobileImage: string,
}

const HomeHeaderWrapper = styled.div<ImagesHomeHeader>`
  width: 100%;
  height: 420px;
  background: ${({ desktopImage }) => (desktopImage ? `url(${desktopImage}) no-repeat center center` : "#cbcbcb")};
  background-size: cover;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  @media (max-width: 745px) {
    background: ${({ desktopImage, mobileImage }) => (mobileImage ? `url(${mobileImage}) no-repeat center center` : `url(${desktopImage}) no-repeat center center`)};
    align-items: center;
    height: 800px;
    background-size: cover;
  }
`;

const RowsWrapper = styled.div`
  width: max-content;
  @media (max-width: 745px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const SlideTitle = styled.h2`
    font-size: 3rem;
    line-height: 3.4rem;
    font-family: telefonicaBold;
    max-width: 350px;
    `

export const HomeHeader: React.FC<HomeHeaderProps> = ({
  image,
  title
}) => {

  return (
    <>
    { image ? (
      <HomeHeaderWrapper desktopImage={image.desktop} mobileImage={image.mobile}>
      <PageContainer containerPadding headerPadding>
        {title && (
          <RowsWrapper>
            <SlideTitle>{title}</SlideTitle>
          </RowsWrapper>
        )}
      </PageContainer>
    </HomeHeaderWrapper>
    ) : null}
    </>
  );
};
