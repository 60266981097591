import React from "react";
import { Toaster } from "react-hot-toast";
import { Routes, Route } from "react-router-dom";
import { CompanyHeader } from "./components";
import { HomePage, LibraryPage, LibraryDetailsPage, CoursesPage, CourseSignUpPage } from "./pages";

const App: React.FC = () => {
  return (
    <>
      <CompanyHeader />
      <Routes >
        <Route path="/" element={<HomePage />} />
        <Route  element={<HomePage />} />
        <Route path="/biblioteca" element={<LibraryPage />} />
        <Route path="/biblioteca/:webinarId" element={<LibraryDetailsPage />} />
        <Route path="/cursos" element={<CoursesPage />} />
        <Route path="/cursos/:courseId" element={<CourseSignUpPage />} />
        <Route path="*" element={<HomePage />} />
      </Routes>
      <Toaster />
    </>
  );
};

export default App;
