import Autocomplete, { AutocompleteProps } from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { CountryType } from "./countries";

interface IProps
  extends Partial<
    AutocompleteProps<
      unknown,
      boolean | undefined,
      boolean | undefined,
      boolean | undefined,
      React.ElementType<any>
    >
  > {
    options: CountryType[]; 
  }

export const CountrySelect: React.FC<IProps> = (props) => {
  return (
    <Autocomplete
      {...props}
      getOptionLabel={(option: any) => option.label}
      renderOption={(props, option: any) => (
        <Box
          component="li"
          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
          {...props}
        >
          <img
            loading="lazy"
            width="20"
            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
            alt=""
          />
          {option.label} ({option.code}) +{option.phone}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          placeholder="Pais"
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password", // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
};
