import { useState, useEffect } from "react";
import axios from "axios";
import { env } from "../../config";

export const useGetSection = () => {
  const [sectionData, setSection] = useState<SectionObj>();
  const [sectionLoading, setSectionLoading] = useState(true);
  const [forceRefresh, setforceRefresh] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setSectionLoading(true);
      try {
        const { data } = await axios.get<SectionObj>(
          `${env.API_URL}/section`,
        );
        setSection(data);
        setSectionLoading(false);
      }
      catch(err) {
        console.log(err)
        setSectionLoading(false);
      }
    }
    fetchData();

    if (forceRefresh) {
      fetchData();
      setforceRefresh(false)
    }
  }, [forceRefresh]);
  return { sectionData, setSection, sectionLoading, setforceRefresh };
};
