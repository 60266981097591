import styled from "styled-components";
import {
  CarouselHeader,
  PageContainer,
  Select,
  SelectLabel,
  SelectWrapper,
  WebinarCard,
} from "../../components";
import { getYoutubeThumbnail, useScrollNavigate } from "../../helpers";
import {
  useGetCategories,
  useGetRecordedCourses,
} from "../../services";
import { useGetCarousel } from "../../services/useGetCarousels";
import { HomeHeader } from "../../components/HomeHeader";
import homeBanner from "../../assets/images/home-banner.jpg";
import homeBannerMobile from "../../assets/images/home-banner-mobile.jpg";

const WebinarsGrid = styled.div`
  display: grid;
  grid-template-columns: 4fr 4fr 4fr;
  grid-column-gap: 15px;
  grid-row-gap: 25px;

  @media (max-width: 1200px) {
    display: flex;
    flex-wrap: wrap;
    column-gap: 15px;
    row-gap: 25px;
  }
`;

const FiltersWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
  margin-bottom: 60px;
`;

export const LibraryPage: React.FC = () => {
  let navigate = useScrollNavigate();

  const { recordedCourses, setCategoryId } =
    useGetRecordedCourses({
      projection: "libraryView",
    });

  const { categories } = useGetCategories();

  const { carouselData } = useGetCarousel("biblioteca");

  const onChangeCategory: React.ChangeEventHandler<HTMLSelectElement> = (
    event
  ) => {
    const categoryId = parseInt(event.target.value);
    if (categoryId === 0) setCategoryId(null);
    else setCategoryId(categoryId);
  };

  const singleItem = (
    <HomeHeader
    image={
      carouselData?.slides.length === 1
      ? {
        desktop: carouselData.slides[0]?.slideDesktopImageUrl || homeBanner,
        mobile: carouselData.slides[0]?.slideMobileImageUrl || homeBannerMobile
      }: null
    }
    title={carouselData?.slides[0]?.title}
    />
  );

  return (
    <>
      {carouselData && carouselData.slides.length > 1 ? (
        <CarouselHeader slides={carouselData.slides} />
      ) : (
        singleItem
      )}
      <PageContainer containerPadding>
        <FiltersWrapper>
          <SelectWrapper>
            <SelectLabel>filtrar por categoría</SelectLabel>
            <Select onChange={onChangeCategory} name="categories">
              <option value="0">Todas</option>
              {categories?.map(({ name, id }, index) => (
                <option key={index} value={id}>
                  {name}
                </option>
              ))}
            </Select>
          </SelectWrapper>
        </FiltersWrapper>
        <WebinarsGrid>
          {recordedCourses?.map(
            (
              {
                category,
                title,
                description,
                dark,
                youtubeUrl,
                id,
                categoryId,
              },
              index
            ) => (
              <WebinarCard
                category={category}
                title={title}
                description={description}
                thumbnail={
                  youtubeUrl ? getYoutubeThumbnail(youtubeUrl) : undefined
                }
                onClick={() => navigate(`/biblioteca/${id}`)}
                dark={categoryId % 2 === 0}
                key={index}
              />
            )
          )}
        </WebinarsGrid>
      </PageContainer>
    </>
  );
};
