import styled from "styled-components";

interface IProps {
  embedId: string;
}

const VideoResponsive = styled.div`
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
  @media (max-width: 1000px) {
    width: 85vw;
    height: 50vw;
    padding-bottom: 0px;
    margin-bottom: 50px;
  }
`;

const VideoResponsiveIframe = styled.iframe`
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  @media (max-width: 1000px) {
    position: initial;
  }
`;

export const YoutubeEmbed: React.FC<IProps> = ({ embedId }) => (
  <VideoResponsive className="video-responsive">
    <VideoResponsiveIframe
      width="853"
      height="480"
      src={`https://www.youtube.com/embed/${embedId}`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
  </VideoResponsive>
);
