import { useParams } from "react-router-dom";
import styled from "styled-components";
import {
  Chip,
  LibraryHeader,
  RoundedButton,
  Subtitle,
  Title,
  Text,
  WebinarCard,
  YoutubeEmbed,
  PageContainer,
  WebinarsCarousel,
  SubjectsList,
} from "../../components";
import downloadIconSrc from "../../assets/images/download.svg";
import {
  getYoutubeId,
  getYoutubeThumbnail,
  useScrollNavigate,
} from "../../helpers";
import { useGetLibraryDetails } from "../../services/useGetLibraryDetails";

interface BreadcrumbTextProps {
  bold?: boolean;
  link?: boolean;
}

const BreadcrumbText = styled.div<BreadcrumbTextProps>`
  font-size: 1rem;
  text-transform: uppercase;
  ${({ bold }) =>
    bold
      ? `
  font-family: telefonicaBold;
  line-height: 1.2;
  `
      : ""}
  margin-right: 0.3rem;
  ${({ link }) => (link ? "cursor:pointer;" : "")}
`;

const BreadcrumbContainer = styled.div`
  display: flex;
  padding-left: 15px;
  margin-top: 30px;
  margin-bottom: 70px;
  @media (max-width: 1000px) {
    display: none;
  }
`;

interface BreadcrumbProps {
  webinarName: string;
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ webinarName }) => {
  const navigate = useScrollNavigate();
  return (
    <BreadcrumbContainer>
      <BreadcrumbText link onClick={() => navigate("/biblioteca")}>
        biblioteca de cursos gratuitos
      </BreadcrumbText>
      <BreadcrumbText>|</BreadcrumbText>
      <BreadcrumbText bold>{webinarName}</BreadcrumbText>
    </BreadcrumbContainer>
  );
};

const LibraryDetailsContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 5fr 7fr;
  align-items: center;
  grid-column-gap: 80px;
  margin-top: 50px;
  @media (max-width: 1000px) {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }
`;

const LibraryDetailsTitle = styled(Title)`
  margin-top: 12px;
`;

const LibraryDetailsSubtitle = styled(Subtitle)`
  margin-top: 20px;
`;

const LibraryDetailsText = styled(Text)`
  font-size: 1rem;
`;

const ChipNegativeMargin = styled(Chip)`
  margin-left: -20px;
  @media (max-width: 600px) {
    transform: scale(0.9);
  }
`;

const DownloadButton = styled(RoundedButton)`
  margin-top: 60px;
  background: url(${downloadIconSrc});
  background-size: 1.3rem;
  background-position-x: 94%;
  background-position-y: center;
  background-repeat: no-repeat;
  text-align: left;
  min-width: initial;
  padding-right: 50px;
  line-height: 1rem;
`;

const WebinarsGrid = styled.div`
  display: grid;
  grid-template-columns: 4fr 4fr 4fr;
  grid-column-gap: 15px;
  grid-row-gap: 25px;
  margin-top: 60px;
  @media (max-width: 1080px) {
    display: none;
  }
`;

const WebinarsCarouselWrapper = styled.div`
  background-color: #ecf0f1;
  padding-top: 30px;
  padding-bottom: 60px;
  display: flex;
  justify-content: center;
  @media (min-width: 1080px) {
    display: none;
  }
`;

const TagsWrapper = styled.div`
  text-transform: uppercase;
  opacity: 0.7;
  margin-top: 10px;
  font-size: 1rem;
  line-height: 1.2rem;
  margin-left: -6px;
`;

export const LibraryDetailsPage: React.FC = () => {
  const { webinarId } = useParams();
  const { recordedCourse, recommendedCourses } = useGetLibraryDetails(
    parseInt(webinarId || "1")
  );

  let navigate = useScrollNavigate();
  return (
    <>
      <LibraryHeader />
      {recordedCourse && (
        <PageContainer
          containerPadding
          contentStyle={{ paddingBottom: "60px" }}
        >
          <Breadcrumb webinarName={recordedCourse.title} />
          <LibraryDetailsContentWrapper>
            <div>
              <ChipNegativeMargin dark={recordedCourse.categoryId % 2 === 0}>
                {recordedCourse.category}
              </ChipNegativeMargin>
              {Boolean(recordedCourse.recordedCourseTags?.length) && (
                <TagsWrapper>
                  {recordedCourse.recordedCourseTags
                    ?.map(({ name }) => name)
                    .join(" - ")}
                </TagsWrapper>
              )}
              <LibraryDetailsTitle>{recordedCourse.title}</LibraryDetailsTitle>
              <LibraryDetailsSubtitle>Descripción</LibraryDetailsSubtitle>
              <LibraryDetailsText>
                {recordedCourse.description}
              </LibraryDetailsText>
              {recordedCourse.subjects && (
                <>
                  <LibraryDetailsSubtitle>
                    Temas a tratar
                  </LibraryDetailsSubtitle>
                  <SubjectsList subjects={recordedCourse.subjects} />
                </>
              )}
              <LibraryDetailsSubtitle>¡Anímate!</LibraryDetailsSubtitle>
              <DownloadButton
                onClick={() => window.open(recordedCourse.contentUrl, "_blank")}
              >
                Descargar Material
              </DownloadButton>
            </div>
            <YoutubeEmbed
              embedId={getYoutubeId(recordedCourse.youtubeUrl) || ""}
            />
          </LibraryDetailsContentWrapper>
        </PageContainer>
      )}
      <PageContainer
        style={{
          backgroundColor: "#ecf0f1",
        }}
        contentStyle={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        containerPadding
      >
        <LibraryDetailsTitle style={{ textAlign: "center" }}>
          Cursos que podrían interesarte
        </LibraryDetailsTitle>
        <WebinarsGrid style={{ paddingBottom: "60px" }}>
          {recommendedCourses &&
            recommendedCourses.map(
              ({ category, title, description, youtubeUrl, id }, index) => (
                <WebinarCard
                  category={category}
                  title={title}
                  description={description}
                  thumbnail={
                    youtubeUrl ? getYoutubeThumbnail(youtubeUrl) : undefined
                  }
                  onClick={() => navigate(`/biblioteca/${id}`)}
                  key={index}
                />
              )
            )}
        </WebinarsGrid>
      </PageContainer>
      {recommendedCourses && recommendedCourses.length && (
        <WebinarsCarouselWrapper>
          <WebinarsCarousel items={recommendedCourses} />
        </WebinarsCarouselWrapper>
      )}
    </>
  );
};
