import styled from "styled-components";

export const PageContainerWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const PageContainerContent = styled.div<{ padding?: boolean, headerPadding?: boolean }>`
  width: 90%;
  max-width: 1500px;
  @media (max-width: 640px) {
    width: 99%;
  }
  ${({ padding }) =>
    padding
      ? `
  padding: 60px;
  @media (max-width: 550px) {
    padding: 15px;
  }
  `
      : ""}
  ${({ headerPadding }) =>
    headerPadding
      ? `
  @media (max-width: 550px) {
    padding: 15px;
    padding-bottom: 60px;
  }
  `
      : ""}
`;

interface IProps extends React.HTMLProps<HTMLDivElement> {
  contentStyle?: React.CSSProperties;
  children?: React.ReactNode;
  containerPadding?: boolean;
  headerPadding?: boolean;
}

export const PageContainer: React.FC<IProps> = ({
  children,
  style,
  contentStyle,
  containerPadding,
  headerPadding
}) => {
  return (
    <PageContainerWrapper style={style} >
      <PageContainerContent style={contentStyle} padding={containerPadding||headerPadding} headerPadding={headerPadding}>
        {children}
      </PageContainerContent>
    </PageContainerWrapper>
  );
};
