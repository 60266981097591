import React, { useState } from "react";
import { useSpringCarousel } from "react-spring-carousel";
import styled from "styled-components";
import { ArrowIconRight, ArrowIconLeft } from "../ArrowCarousel";
import { HomeHeader } from "../HomeHeader";

const CarouselBody = styled.div`
  max-width: 100vw;
  width: 100%;
  background-color: #fff;
  overflow: hidden;
  position: relative;
`;

const NavigationButtonContainer = styled.div`
  position: absolute;
  height: 30px;
  width: 30px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: rgba(100, 100, 100, 0.5);
  border-radius: 50%;

  &:hover {
    background-color: rgba(122, 122, 122, 0.8);
  }
`;

const PrevButton = styled(NavigationButtonContainer)`
  left: 50px;
`;

const NextButton = styled(NavigationButtonContainer)`
  right: 50px;
`;

const CarouselThumb = styled.div`
  cursor: pointer;
`;

const BulletContainer = styled.div`
  position: absolute;
  bottom: 10px;
  left: 50%;
  display: flex;
  align-items: center;
`;
interface ActiveBullet {
  isActive: boolean;
}

const Bullet = styled.div<ActiveBullet>`
  height: 10px;
  width: 10px;
  opacity: 1;
  background-color: ${({ isActive }) => (isActive ? "#fff" : "transparent")};
  border-radius: 50%;
  border: 2px solid #fff;
  margin: 0 3px;
`;

interface CarouselHeaderProps {
  slides: Slide[];
}

export const CarouselHeader: React.FC<CarouselHeaderProps> = ({ slides }) => {

  const [activeIndex, setActiveIndex] = useState(0);

  const singleItem = (element:Slide) => (
    <HomeHeader
      image={
        element
        ? {
          desktop: element.slideDesktopImageUrl || "",
          mobile: element.slideMobileImageUrl || ""
        }: null
      }
      title={element.title}
    />
  );

  const {
    carouselFragment,
    thumbsFragment,
    slideToPrevItem,
    slideToNextItem,
    slideToItem,
  } = useSpringCarousel({
    withLoop: true,
    withThumbs: true,
    items: slides.map((slide) => ({
      id: String(slide.id),
      renderItem: singleItem(slide),
      renderThumb: (
        <CarouselThumb
          onClick={() => handleSlideChange(String(slide.id))}
          key={slide.id}
        >
          <Bullet isActive={activeIndex === Number(slide.id)} />
        </CarouselThumb>
      ),
    })),
  });

  const handleSlideChange = (index: string) => {
    setActiveIndex(Number(index));
    slideToItem(index);
  };

  const handleSlidePrev = () => {
    setActiveIndex(0);
    slideToPrevItem();
  };

  const handleSlideNext = () => {
    setActiveIndex(0);
    slideToNextItem();
  };

  return (
    <CarouselBody>
      {carouselFragment}
      <BulletContainer>{thumbsFragment}</BulletContainer>
      <PrevButton onClick={handleSlidePrev}>
        <ArrowIconLeft />
      </PrevButton>
      <NextButton onClick={handleSlideNext}>
        <ArrowIconRight />
      </NextButton>
    </CarouselBody>
  );
};
