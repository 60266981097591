import styled from "styled-components";
import libraryBanner from "../../assets/images/library-banner.webp";
import { PageContainer } from "../PageContainer";

const LibraryHeaderWrapper = styled.div`
  width: 100%;
  height: 380px;
  background: url(${libraryBanner}) no-repeat center center;
  background-size: cover;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const LibraryHeaderTitle = styled.h1`
  font-size: 3rem;
  font-family: telefonicaBold;
  @media (max-width: 1000px) {
    font-size: 2rem;
    line-height: 2.2rem;
    margin-bottom: 10px;
  }
`;

const LibraryHeaderSubtitle = styled.h2`
  font-size: 2rem;
  font-family: telefonicaBold;
  @media (max-width: 1000px) {
    font-size: 1rem;
    line-height: 1.2rem;
  }
`;

export const LibraryHeader: React.FC = () => {
  return (
    <LibraryHeaderWrapper>
      <PageContainer containerPadding headerPadding>
        <LibraryHeaderTitle>Biblioteca de cursos gratuitos</LibraryHeaderTitle>
        <LibraryHeaderSubtitle>
          Selecciona la categoría de tu interés para ver los cursos disponibles
        </LibraryHeaderSubtitle>
      </PageContainer>
    </LibraryHeaderWrapper>
  );
};
