export const getYoutubeId = (url: string) => {
  var regExp =
    /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  var match = url.match(regExp);
  if (match && match[2].length === 11) {
    return match[2];
  } else {
    //error
  }
};

export const getYoutubeThumbnail = (url: string) => {
  return `https://img.youtube.com/vi/${getYoutubeId(url)}/hqdefault.jpg
  `;
}