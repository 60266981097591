import { PropsWithChildren } from "react";
import styled from "styled-components";
import { trim } from "../../helpers/trim";
import { Chip } from "../Chip";

const WebinarCardWrapper = styled.div<{isDragging?:boolean}>`
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 25px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  min-height: 400px;
  max-width: 450px;
  background-color: white;
  cursor: pointer;
  @media(max-width: 300px){
    max-width: 93vw;
  }
`;

interface WebinarCardHeaderProps {
  imageUrl?: string;
}

const WebinarCardHeader = styled.div<WebinarCardHeaderProps>`
  height: 250px;
  width: 100%;
  background: ${({ imageUrl }) => (imageUrl ? `url(${imageUrl}) no-repeat center center` : "#cbcbcb")};
  background-size: cover;
  padding: 30px;
  padding-bottom: 20px;
  @media(max-width: 500px){
    padding: 15px;
  }
  display: flex;
  align-items: flex-end;
  position: relative;
`;

const WebinarCardHeaderGradient = styled.div`
  background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.6) 25%, rgba(0,0,0,0) 100%);
  opacity: 0.8;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const RaisedChip = styled(Chip)`
  z-index: 2;
  white-space: nowrap;
  @media(max-width: 500px){
    font-size: 0.6rem;
    height: 1.2rem;
    line-height: 1.15rem;
  }
`;

const WebinarCardContent = styled.div`
  padding: 30px;
  padding-top: 0px;
  
`;

const WebinarCardTitle = styled.h3`
  font-family: telefonicaBold;
  font-size: 1.2rem;
  margin-top: 20px;
  margin-bottom: 20px;
  ${({onClick})=>Boolean(onClick)?"cursor:pointer;":""}
`;

const WebinarCardText = styled.p`
  font-size: 1rem;
`;

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  category: string;
  title: string;
  description: string;
  dark?: boolean;
  thumbnail?: string;
  onClickTitle?: () => void;
}

export const WebinarCard: React.FC<PropsWithChildren<IProps>> = ({
  category,
  title,
  description,
  dark,
  thumbnail,
  onClickTitle,
  ...restProps
}) => {
  
  return (
    <WebinarCardWrapper {...restProps}>
      <WebinarCardHeader imageUrl={thumbnail}>
        <WebinarCardHeaderGradient />
        <RaisedChip dark={dark}>{category}</RaisedChip>
      </WebinarCardHeader>
      <WebinarCardContent>
        <WebinarCardTitle onClick={onClickTitle}>{trim(title,100)}</WebinarCardTitle>
        <WebinarCardText>{trim(description,300)}</WebinarCardText>
      </WebinarCardContent>
    </WebinarCardWrapper>
  );
};
