import { useState, useEffect } from "react";
import axios from "axios";
import { env } from "../../config";

export const useGetCategories = () => {
  const [categories, setCategories] = useState<Category[]>();
  const [categoriesLoading, setCategoriesLoading] = useState(true);
  useEffect(() => {
    (async () => {
      setCategoriesLoading(true);
      const {
        data: {
          _embedded: { categories: _categories },
        },
      } = await axios.get<CategoriesResponse>(
        `${env.API_URL}/categories`
      );
      setCategories(_categories);
      setCategoriesLoading(false);
    })();
  }, []);
  return { categories, setCategories, categoriesLoading };
};
