import styled from "styled-components";

interface IProps {
  filled?: boolean;
  animated?: boolean;
}

export const RoundedButton = styled.button<IProps>`
  border: none;
  min-width: 320px;
  transition: color 0.2s, background-color 0.2s;
  ${({ filled, animated }) =>
    filled
      ? `
  background-color: #019af1;
  color: white;
  ${
    animated
      ? `&:hover {
    color: #0A2739;
    background-color: white;
  }`
      : ""
  }
  `
      : `
      border: 1px solid #0B2738;
      color: #0B2738;
      background-color: none;
      background: none;
  `}

  height: 45px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 100px;
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 500;
  font-family: "telefonicaBold";
  cursor: pointer;
  ${({disabled})=>disabled?`
    background: #F2F2F2 !important;
    color: #0f0f0f !important;
  `:``}
  @media (max-width: 400px) {
    min-width: unset;
    font-size: 0.8rem;
  }
  line-height: 1rem;
`;
