import { useState, useEffect } from "react";
import axios from "axios";
import { env } from "../../config";

export const useGetBenefits = () => {
  const [benefitData, setBenefitData] = useState<BenefitsObj>();
  const [benefitLoading, setBenefitLoading] = useState(true);
  const [forceRefresh, setforceRefresh] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setBenefitLoading(true);
      try {
        const { data } = await axios.get<BenefitsObj>(
          `${env.API_URL}/benefitSection`,
        );
        setBenefitData(data);
        setBenefitLoading(false);
      }
      catch(err) {
        console.log(err)
        setBenefitLoading(false);
      }
    }
    fetchData();

    if (forceRefresh) {
      fetchData();
      setforceRefresh(false)
    }
  }, [forceRefresh]);
  return { benefitData, setBenefitData, benefitLoading, setforceRefresh };
};
