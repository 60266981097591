import { useState, useEffect } from "react";
import axios from "axios";
import { env } from "../../config";

export const useGetLibraryDetails = (id?: number) => {
  const [recommendedCourses, setRecommendedCourses] = useState<RecordedCourse[]>();
  const [recommendedCoursesLoading] = useState(true);
  //const [categoryId, setCategoryId] = useState<number | undefined>();
  const [recordedCourse, setRecordedCourse] = useState<RecordedCourse>();
  const [recordedCourseLoading, setRecordedCourseLoading] = useState(true);

  useEffect(() => {
    (async () => {
      setRecordedCourseLoading(true);
      if (id) {
        const { data: _recordedCourse } = await axios.get<RecordedCourse>(
          `${env.API_URL}/recordedCourses/${id}?projection=libraryDetailsView`
        );
        setRecordedCourse(_recordedCourse);
        //setCategoryId(_recordedCourse.categoryId);
        setRecordedCourseLoading(false);
        const getRecommendedUrl = `${env.API_URL}/library/recommendedCourses?categoryId=${_recordedCourse?.categoryId}&courseId=${id}`;
        const { data: _recommendedCourses } = await axios.get<RecordedCourse[]>(
          getRecommendedUrl
        );
        setRecommendedCourses(_recommendedCourses);
      }
    })();
  }, [id]);

  return {
    recordedCourse,
    recordedCourseLoading,
    recommendedCourses,
    setRecommendedCourses,
    recommendedCoursesLoading,
    //setCategoryId,
  };
};
