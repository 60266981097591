import { useState } from "react";
import styled from "styled-components";
import { RoundedButton } from "../RoundedButton";

interface ModalOverlayWrapperProps {
  open?: boolean;
}

const ModalOverlayWrapper = styled.div<ModalOverlayWrapperProps>`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00000052;
  z-index: 100;
  opacity: 0;
  ${({ open }) => (open ? "opacity: 1;" : "display: none;")}
  transition: opacity 0.8s;
`;

const ModalCard = styled.div`
  background-color: white;
  padding: 20px;
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 500px;
  z-index: 101;
  margin-left: 5px;
  margin-right: 5px;
`;

const ModalTitle = styled.h1`
  font-family: telefonicaBold;
  font-size: 1.1rem;
  text-transform: uppercase;
  color: #0a2739;
`;

const ModalContent = styled.pre`
  font-size: 1rem;
  opacity: 0.7;
  font-weight: 300;
  max-height: 60vh;
  max-width: 100%;
  white-space: break-spaces;
  overflow-y: scroll;
`;

const ModalCardActionButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

interface ModalActionButtonProps {
  title: string;
  onClick: () => void;
}

interface IProps {
  acceptButton?: ModalActionButtonProps;
  title?: string;
  description?: string;
  open?: boolean;
  onClose: () => void;
}

export const Modal: React.FC<IProps> = ({
  acceptButton,
  title,
  description,
  open,
  onClose,
}) => {
  return (
    <ModalOverlayWrapper onClick={onClose} open={open}>
      <ModalCard onClick={(e) => e.stopPropagation()}>
        <ModalTitle>{title}</ModalTitle>
        <ModalContent>{description}</ModalContent>
        <ModalCardActionButtonsWrapper>
          {acceptButton ? (
            <>
              <RoundedButton style={{ minWidth: "80px" }} onClick={onClose}>Cancelar</RoundedButton>
              <RoundedButton
                filled
                style={{ minWidth: "80px" }}
                onClick={() => {
                  acceptButton.onClick();
                  onClose();
                }}
              >
                {acceptButton.title}
              </RoundedButton>
            </>
          ) : (
            <RoundedButton filled onClick={onClose}>
              Aceptar
            </RoundedButton>
          )}
        </ModalCardActionButtonsWrapper>
      </ModalCard>
    </ModalOverlayWrapper>
  );
};

interface UseModalConfig {
  title?: string;
  description?: string;
  acceptButton?: ModalActionButtonProps;
}

export const useModal = ({
  title: _title,
  description: _description,
  acceptButton: _acceptButton,
}: UseModalConfig) => {
  const [open, setIsOpen] = useState(false);
  const [title, setTitle] = useState(_title);
  const [description, setDescription] = useState(_description);
  const [acceptButton, setAcceptButton] = useState(_acceptButton);

  interface openModalConfigs {
    title: string;
    description: string;
    cb?: () => void;
    acceptButtonTitle?: string;
  }

  const openModal = ({
    title,
    description,
    cb,
    acceptButtonTitle,
  }: openModalConfigs) => {
    setIsOpen(true);
    setTitle(title);
    setDescription(description);
    if (cb) {
      setAcceptButton({ onClick: cb, title: acceptButtonTitle || "Aceptar" });
    }
  };
  return {
    open,
    title,
    description,
    acceptButton,
    openModal,
    onClose: () => setIsOpen(false),
    onOpen: () => setIsOpen(true),
  };
};
