import { useState, useEffect } from "react";
import axios from "axios";
import { env } from "../../config";

type GetRecordedCoursesConfig = {
  projection: string;
};

export const useGetRecordedCourses = ({
  projection,
}: GetRecordedCoursesConfig) => {
  const [recordedCourses, setRecordedCourses] = useState<RecordedCourse[]>();
  const [recordedCoursesLoading, setRecordedCoursesLoading] = useState(true);
  const [categoryId, setCategoryId] = useState<number | null>(null);
  const [recordedCourseTagId, setRecordedCourseTagId] = useState<number | null>(
    null
  );
  useEffect(() => {
    (async () => {
      setRecordedCoursesLoading(true);
      const endpoint = `${env.API_URL}/recordedCourses`;
      let url = `${endpoint}?projection=${projection}`;
      if (categoryId && !recordedCourseTagId) {
        url = `${endpoint}/search/findByCategoryId?categoryId=${categoryId}&projection=${projection}`;
      }
      if (recordedCourseTagId && !categoryId) {
        url = `${endpoint}/search/findByTagId?tagId=${recordedCourseTagId}&projection=${projection}`;
      }
      if (recordedCourseTagId && categoryId) {
        url = `${endpoint}/search/findByCategoryIdAndTagId?tagId=${recordedCourseTagId}&categoryId=${categoryId}&projection=${projection}`;
      }
      const {
        data: {
          _embedded: { recordedCourses: _recordedCourses },
        },
      } = await axios.get<RecordedCoursesResponse>(url);
      setRecordedCourses(_recordedCourses);
      setRecordedCoursesLoading(false);
    })();
  }, [categoryId, projection, recordedCourseTagId]);
  return {
    recordedCourses,
    setRecordedCourses,
    recordedCoursesLoading,
    setCategoryId,
    setRecordedCourseTagId,
  };
};
