import styled from "styled-components";
import { CertifiedCourseBadge } from "../Chip";
import { CourseData } from "../CourseData";
import { PageContainer } from "../PageContainer";

interface CourseDisplayImageProps {
  courseDisplayImage?: string;
}

const CourseDisplayImage = styled.div<CourseDisplayImageProps>`
  min-width: 500px;
  min-height: 500px;
  max-height: 500px;
  background-size: cover !important;
  ${({ courseDisplayImage }) =>
    courseDisplayImage
      ? `background: url(${courseDisplayImage}) no-repeat center center;`
      : `background: #cbcbcb;`}
  @media (max-width: 1000px) {
    min-width: 400px;
    min-height: 400px;
    max-height: 400px;
  }
  @media (max-width: 600px) {
    min-width: 300px;
    min-height: 300px;
    max-height: 300px;
  }
`;

const CourseDisplayCertifiedCourseMargin = styled.div`
  margin-top: 30px;
  z-index: 1;
`;

const CourseDisplayFlex = styled.div`
  display: flex;
  gap: 40px;
  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
  }
`;

interface IProps {
  course: Course;
  dark?: boolean;
}

export const CourseDisplay: React.FC<IProps> = ({ course, dark }) => {
  return (
    <PageContainer
      style={{ backgroundColor: dark ? "#ECF0F1" : "" }}
      containerPadding
      contentStyle={{paddingTop: "100px"}}
    >
      <CourseDisplayFlex>
        <CourseDisplayImage courseDisplayImage={course.imageUrl}>
          <CourseDisplayCertifiedCourseMargin>
            <CertifiedCourseBadge />
          </CourseDisplayCertifiedCourseMargin>
        </CourseDisplayImage>
        <CourseData dark={dark} course={course} />
      </CourseDisplayFlex>
    </PageContainer>
  );
};
