import styled from "styled-components";

export const Title = styled.div`
  font-size: 2rem;
  font-family: telefonicaBold;
`;

export const Subtitle = styled.div`
  font-size: 1.2rem;
  font-family: telefonicaBold;
`;

export const Text = styled.div`
  font-size: 1rem;
`;